var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { "no-gutters": "", align: "end", justify: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "4" } },
        [
          _c("v-img", {
            attrs: { id: "bodyImage", src: _vm.bodyImage, contain: "" },
            on: {
              load: function($event) {
                return _vm.bodyImageOnLoad()
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "4" } },
        [
          _vm.bodyImageLoaded
            ? _c("v-img", {
                attrs: {
                  id: "tableImage",
                  src: _vm.deskImage,
                  height: _vm.deskHeight,
                  contain: ""
                },
                on: {
                  load: function($event) {
                    return _vm.initialiseHeights()
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }