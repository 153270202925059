<template>
  <v-row no-gutters align="end" justify="center">
    <v-col cols="4"
      ><v-img id="bodyImage" :src="bodyImage" contain @load="bodyImageOnLoad()"
    /></v-col>
    <v-col cols="4"
      ><v-img
        v-if="bodyImageLoaded"
        id="tableImage"
        :src="deskImage"
        :height="deskHeight"
        contain
        @load="initialiseHeights()"
    /></v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      deskHeight: 0,
      bodyImageLoaded: false
    };
  },
  props: {
    results: Object
  },
  created() {
    window.addEventListener("resize", this.initialiseHeights);
  },
  destroyed() {
    window.removeEventListener("resize", this.initialiseHeights);
  },
  computed: {
    deskImage() {
      if (this.results.setup.deskHeight === 0.33) {
        return require("@/assets/images/sideSimsImages/tableSmall.svg");
      } else if (this.results.setup.deskHeight === 0.48) {
        return require("@/assets/images/sideSimsImages/tableMedium.svg");
      }
      return require("@/assets/images/sideSimsImages/tableLarge.svg");
    },
    bodyImage() {
      switch (this._i18n.locale) {
        case "en-gb":
        case "en-us":
          return require("@/assets/images/sideSimsImages/seatHeightBodyMapEn.png");
        case "es":
        case "es-mx":
          return require("@/assets/images/sideSimsImages/seatHeightBodyMapEs.png");
        default:
          return require("@/assets/images/sideSimsImages/seatHeightBodyMapEn.png");
      }
    }
  },
  methods: {
    bodyImageOnLoad() {
      this.bodyImageLoaded = true;
      this.initialiseHeights();
    },
    initialiseHeights() {
      this.setDeskHeight();
    },
    setDeskHeight() {
      var bodyImage = document.getElementById("bodyImage");
      var scale = this.results.setup.deskHeight
        ? this.results.setup.deskHeight
        : 0.6;
      if (bodyImage) {
        this.deskHeight = bodyImage.clientHeight * scale;
      }
    }
  },
  watch: {
    results: {
      deep: true,
      handler() {
        this.initialiseHeights();
      }
    }
  }
};
</script>
